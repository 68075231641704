import Head from "next/head";
import Script from "next/script";
import { NextSeo } from "next-seo";
import React from "react";

interface Props {
  title: string;
  description: string;
  image?: { url: string; alt: string; width: number; height: number };
  noindex?: boolean;
  nofollow?: boolean;
  pageTitle?: string;
}

const SEO: React.FC<Props> = ({
  title,
  description,
  pageTitle,
  nofollow,
  noindex,
  image,
}) => {
  const isProduction = process.env.NEXT_PUBLIC_ENVIRONMENT === "production";
  const defaultImage = {
    url: "https://d3nok0m49vtvbs.cloudfront.net/logo.jpg",
    alt: "OMERTA",
    width: 480,
    height: 95,
  };

  const googleTagManager = `
    window.dataLayer = window.dataLayer || [];
    function gtag() { dataLayer.push(arguments); }
    gtag('js', new Date());
    gtag('config', 'G-WHJTP1CNEL');
  `;
  return (
    <>
      <NextSeo
        title={title}
        description={description}
        noindex={!isProduction || noindex}
        nofollow={!isProduction || nofollow}
        openGraph={{
          title: pageTitle || title,
          description,
          site_name: "OMERTA",
          images: [image ?? defaultImage],
        }}
        twitter={{
          handle: "@Omerta_officiel",
          site: "@Omerta_officiel",
          cardType: "summary_large_image",
        }}
      />
      <Head>
        <title>{pageTitle ?? title}</title>
        <meta name="description" content={description} />
        <link rel="icon" href={"../../../logo.svg"}></link>
      </Head>
      <Script
        id="gtag"
        async
        src="https://www.googletagmanager.com/gtag/js?id=G-WHJTP1CNEL"
      />
      <Script
        id="G-WHJTP1CNEL"
        async
        dangerouslySetInnerHTML={{ __html: googleTagManager }}
      />
    </>
  );
};

export default SEO;
