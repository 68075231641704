import { LazyLoadImage } from "react-lazy-load-image-component";

import { Media } from "~/src/types/models/article";

import styles from "./index.module.scss";

type Props = Media & {
  featured?: boolean;
  videos?: boolean;
};

const HomeArticleMedia = ({
  path,
  type,
  caption,
  credit,
  featured = false,
  videos = false,
}: Props) => {
  return (
    <figure className={styles.homeArticleMedia}>
      {type.toLowerCase() === "image" && (
        <LazyLoadImage
          src={path}
          className={featured ? `${styles.featuredMedia}` : styles.media}
          alt={caption ?? credit ?? path}
        />
      )}
      {type.toLowerCase() === "video" && (
        <video
          src={path}
          className={videos ? styles.video : styles.media}
          controls={true}
        >
          {/* <source src={imageUrl} type="video/wav" /> */}
        </video>
      )}
      {!!caption && (
        <figcaption className={styles.figureCaption}>
          {caption}{" "}
          {!!credit && (
            <span className={styles.figureSubCaption}>
              © {type === "video" ? "Video" : "Photo"} {credit}
            </span>
          )}
        </figcaption>
      )}
    </figure>
  );
};

export default HomeArticleMedia;
