import Link from "next/link";
import { useEffect, useState } from "react";

import { useBreakpoint } from "~/src/contexts/providers/breakpoint";
import { Media, OptionalArticle } from "~/src/types/models/article";
import { categoryNames } from "~/src/utils/constants/categories";
import { ARTICLE_PAGE } from "~/src/utils/constants/page";

import styles from "./index.module.scss";
import HomeArticleMedia from "./Media";

type Props = OptionalArticle & {
  slug?: string;
  media?: Media;
  main?: boolean;
  desktopVideo?: boolean;
  authorUnderTitle?: boolean;
  other?: boolean;
  featured?: boolean;
  videos?: boolean;
  openInNewTab?: boolean;
  firstMainArticle?: boolean;
  notClickableView?: boolean;
  hideCategory?: boolean;
};

const HomeArticle = ({
  slug,
  title,
  category,
  author,
  header,
  media,
  main = false,
  desktopVideo = false,
  authorUnderTitle = false,
  other = false,
  featured = false,
  videos = false,
  openInNewTab,
  firstMainArticle = false,
  notClickableView = false,
  hideCategory = false,
}: Props) => {
  const Media =
    media != undefined ? (
      <HomeArticleMedia {...media} featured={featured} videos={videos} />
    ) : null;
  const [isVideo, setIsVideo] = useState(false);
  const [isImage, setIsImage] = useState(false);
  useEffect(() => {
    if (media?.type === "video") {
      setIsVideo(true);
    } else if (media?.type === "image") {
      setIsImage(true);
    }
  }, [media?.type]);
  const breakpoint = useBreakpoint();
  const isDesktop = breakpoint == "XL" || breakpoint == "XXL";

  return (
    <Link href={notClickableView ? "" : `${ARTICLE_PAGE}/${slug}`}>
      <a {...(openInNewTab && { target: "_blank", rel: "noreferrer" })}>
        <article
          className={`${styles.homeArticle} ${
            main && !desktopVideo ? styles.main : ""
          } ${featured ? styles.fixedSize : ""} ${
            notClickableView ? styles.notClickable : ""
          }`}
        >
          {main && !desktopVideo && <div>{Media}</div>}
          <div className={styles.details}>
            <div>
              {!hideCategory && !other && (
                <h3 className={styles.category}>{categoryNames[category]}</h3>
              )}
              {main && (isImage || isVideo) && desktopVideo && (
                <div>{Media}</div>
              )}
              {desktopVideo ? (
                <h3
                  className={`${styles.title} ${
                    firstMainArticle && isDesktop ? styles.firstText : ""
                  }`}
                >
                  {title}
                </h3>
              ) : (
                <h2
                  className={`${styles.title} ${
                    firstMainArticle && isDesktop ? styles.firstText : ""
                  }`}
                >
                  {title}
                </h2>
              )}
              {authorUnderTitle && author && (
                <address
                  className={`${styles.author} ${
                    firstMainArticle && isDesktop ? styles.firstAuthorText : ""
                  }`}
                >
                  Par {author}
                </address>
              )}
            </div>
            {!main && Media}
            {header && (
              <p
                className={`${styles.headerText} ${
                  firstMainArticle && isDesktop ? styles.firstText : ""
                }`}
              >
                {header}
              </p>
            )}
            {!authorUnderTitle && author && (
              <address
                className={`${styles.author}  ${
                  firstMainArticle && isDesktop ? styles.firstAuthorText : ""
                }`}
              >
                Par {author}
              </address>
            )}
          </div>
        </article>
      </a>
    </Link>
  );
};

export default HomeArticle;
